.preloader {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f4f4f9;
    z-index: 9999;
}

.preloader p {
    margin-top: 20px;
    font-size: 1.2em;
    color: linear-gradient(90deg, #009ec7, #00b7d0);
}

.dot {
    width: 15px;
    height: 15px;
    margin: 5px;
    background-color: #3498db;
    border-radius: 50%;
    animation: pre 1.2s infinite;
}

.dot1 {
    animation-delay: 0s;
}
.dot2 {
    animation-delay: 0.2s;
}
.dot3 {
    animation-delay: 0.4s;
}

@keyframes pre {
    0%, 80%, 100% {
        transform: scale(0);
        opacity: 0.6;
    }
    40% {
        transform: scale(1);
        opacity: 1;
    }
}