.aboutus-page{
    min-height: 100vh;
    width: 100%;
    color: white;
}

.back-button{
    bottom: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    margin-top: 130px;
    margin-left: 40px;
    background: #00a5d3;
    border: none;
    color: white;
    font-size: 20px;
    font-weight: 600;
    letter-spacing: 1px;
    cursor: pointer;
    transition: 0.3s ease all;
    padding: 5px 10px;
    border-radius: 10px;
    z-index: 10;
}

.back-button:hover{
    transform: scale(1.08);
}

.back-button i{
    font-size: 28px;
    margin-right: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.aboutus-header h1{
    word-spacing: 3px;
    letter-spacing: 2px;
    color: white;
    font-size: 30px;
    font-weight: 700;
    margin-bottom: 30px;
}

.aboutus-header p{
    font-size: 20px;
    margin-bottom: 20px;
}
.aboutus-page div{
    margin-bottom: 50px;
}
.aboutus-page h2{
    display: flex;
    align-items: center;
    
    cursor: pointer;
    font-size: 28px;
    margin-bottom: 30px;
    font-weight: 600;
    
}

.aboutus-page h2 i {
    display: flex;
    align-items: center;
    font-size: 28px;
    transition: .3s ease all;
}

.aboutus-page .content {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.3s ease-out;
}





.tools ul{ 
    margin-left: 100px;
}

.tools ul li{
    padding: 10px 0;
    font-size: 20px;
}

.about-customer {
    display: flex;
    margin-bottom: 20px;
  }
  
  .about-customer-left-side {
    width: 50%;
    background-image: url('../../images/oldsite/hands.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    border-radius: 10px 0 0 10px ;
  }
  
  .about-customer-right-side {
    width: 50%;
    padding: 0 20px;
    background-image: url('../../images/oldsite/53873231_2.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    position: relative;
    color: #fff; /* Optional: Makes text more readable on dark background */
    border-radius: 0 10px 10px 0;
    
  }
  
  .about-customer-right-side::before {
    border-radius: 0 10px 10px 0;
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6); /* Adjust opacity to control darkness */
    z-index: 1;
  }
  
  .about-customer-right-side h2,
  .about-customer-right-side p,
  .customers {
    position: relative;
    z-index: 2; /* Ensures content appears above the dark overlay */
  }

  .about-customer-right-side h2{
    margin-bottom: 0;
    cursor: default;
  }
  
  .customers {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
  }

.customers div{
    margin-bottom: 0;
}

.customers h2{
    font-size: 20px;
    
}

.ots{
    margin-bottom: 20px;
}

.licenses-gallery {
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
    justify-content: space-between;
    margin: 20px 0;
    height: 400px;
    
}

.license-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    text-align: center;

}

.license-thumbnail {
    width: 100px;
    height: 400px;
    object-fit: cover;
    border: 2px solid #ddd;
    border-radius: 5px;
    transition: transform 0.3s ease;
}

.license-thumbnail:hover {
    transform: scale(1.1);
}

.modal-overlay-1 {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modal-content-1 {
    position: relative;
    background: transparent;
    max-width: 90%;
    max-height: 90%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.full-image {
    max-width: 100%;
    max-height: 90vh;
    border-radius: 8px;
}

.close-button {
    position: absolute;
    top: 0px;
    right: 0px;
    background: #00a5d3;
    color: white;
    border: none;
    border-radius: 5px;
    margin: 5px;
    padding: 5px 10px;
    cursor: pointer;
    font-size: 14px;
    transition: 0.3s ease all;
}

.close-button:hover {
    background: #007899;
}

@media (max-width: 1000px) {
  
    .back-button {
        bottom: 30px;
        left: 10px;
        margin-left: 0;
        padding: 5px 15px;
        font-size: 16px;
    }

    .back-button:hover {
        margin-left: 10px;
    }
    .licenses-gallery{
        justify-content: center;
    }


    .aboutus-header p {
        font-size: 16px;
    }

    .aboutus-header h2 {
        font-size: 20px;
    }

    .tools ul {
        padding-left: 20px;
    }

    .tools ul li {
        font-size: 16px;
    }

    .about-customer {
        flex-direction: column;
    }

    .about-customer-left-side {
        display: none;
    }

    .about-customer-right-side {
        width: 100%;
        border-radius: 10px;
    }

    .about-customer-right-side::before {
        border-radius: 10px;
    }

    .customers {
        grid-template-columns: 1fr;
        gap: 10px;
    }

    .customers div {
        margin-bottom: 10px;
    }

    .customers h2 {
        font-size: 18px;
    }

    .ots {
        margin-bottom: 10px;
    }
}
