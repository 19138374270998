.news-content {
    height: 100vh;
    margin-top: 130px;
    padding: 0 13%;
    display: flex;
    flex-direction: column;
}

.section-title {
    text-align: center;
    margin-bottom: 20px;
}

.news-grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr); /* Default to 4 columns */
    gap: 20px;
}

.news-item {
    background: #fff;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    transition: 0.3s ease all;
}

.news-item:hover {
    background-color: #e9ecf9;
}

.news-in-image {
    height: 180px;
    background-size: cover;
    background-position: center;
}

.headline {
    padding: 10px;
}

.headline h2 {
    font-size: 18px;
}

.news-in-text {
    padding: 10px;
}

.news-in-text a {
    color: #007bff;
    text-decoration: none;
}

.news-in-text a:hover {
    text-decoration: underline;
}

.date {
    color: #777;
    font-size: 0.9rem;
    margin: 0 10px;
}


.pagination {
    text-align: center;
    margin-top: 20px;
}

.pagination button {
    background: #151c26b0;
    border: none;
    border-radius: 5px;
    color: white;
    padding: 5px 10px;
    margin: 0 5px;
    cursor: pointer;
    transition: .3s ease all;
    font-weight: 600;
}

.pagination button.active {
    background-color: #00a6ca;
    color: white;
}

.pagination button:hover {
    background-color: #00a6ca;
}


@media (max-width: 1024px) {
    .news-grid {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media (max-width: 768px) {
    .news-grid {
        grid-template-columns: 1fr; 
    }

    .pagination {
        display: flex;
        justify-content: center;
        margin-top: 20px;
    }
}
