.about-container {
    position: relative;
    height: 100vh;
    width: 100%;
    overflow: hidden;
    z-index: 0;
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
  
    background-size: cover;
    background-position: center;
}

.about-container::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.884), rgba(0, 0, 0, 0));

    z-index: -1; /* Обеспечивает, что слой будет позади контента */
}



@keyframes pulse1 {
    0% { transform: scaleX(1); }
    100% { transform: scaleX(1.05); }
}

@keyframes pulse2 {
    0% { transform: scaleX(1); }
    100% { transform: scaleX(1); }
}

@keyframes pulse3 {
    0% { transform: scaleX(1); }
    100% { transform: scaleX(1.07); }
}




.about-us {
    align-items: center;
    display: flex;
    justify-content: center;
    padding: 0 13%;
    color: #ffffff; 
    overflow: hidden;
}

.opis{
    width: 50%;
    padding-left: 30px;
    transform: translateX(100%); /* Начальная позиция для правого блока */
    transition: transform 1s ease-in-out; /* Плавная анимация */
}
.flag-img{
    width: 40%;
 
}
.flag-img img{
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    width: 100%;
}


.about-us h1 {
    margin: 0;
    font-size: 40px;
    font-weight: 800;
    margin-bottom: 10px;
}

.about-us p{
    margin-bottom: 20px;
    font-size: 18px;
    font-weight: 600;
}

.about-us ul{
    margin-left: 20px;
}

.about-us ul li{
    font-size: 16px;
    margin-bottom: 10px;
    font-weight: 600;
}

.more-button {
    display: flex;
    justify-content: end;
}

.more-button button {
    display: flex;
    align-items: center; 
    padding: 10px 20px;
    height: 50px;
    background: var(--gradient-color);
    color: #fff;
    font-weight: 600;
    font-size: 20px;
    border-radius: 10px;
    border: none;
    cursor: pointer;
    outline: none;
    transition: 0.3s ease all;
}

.more-button button:hover {
    transform: scale(1.05);
}

.more-button i {
    font-size: 28px;
    margin-left: 8px; /* Отступ слева для разделения текста и иконки */
    display: flex;
    align-items: center; /* Центрируем иконку по вертикали */
}



.carousel-container {
    width: 40%;
    max-width: 800px; /* Ограничиваем максимальную ширину */
    
    border-radius: 10px; /* Скругленные углы */
    overflow: hidden;
    transform: translateX(-100%); /* Начальная позиция для карусели */
    transition: transform 1s ease-in-out; /* Плавная анимация */
  
}





@media (max-width: 1192px) {
    .flag-img{
        display: none;
    }
    .opis{
        width: 100%;
    }
}

@media (max-width: 700px) {
    .opis ul{
        display: none;
    }
    .more-button{
        justify-content: start;
    }
    .more-button button{
        font-size: 16px;

    }
}


.carousel-container.slide-in-left {
    transform: translateX(0); /* Анимация выезда слева */
}

.opis.slide-in-right {
    transform: translateX(0); /* Анимация выезда справа */
}



@keyframes slide-in-left {
    from {
        transform: translateX(-100%);
    }
    to {
        transform: translateX(0);
    }
}

/* Анимация для правого блока */
@keyframes slide-in-right {
    from {
        transform: translateX(100%);
    }
    to {
        transform: translateX(0);
    }
}