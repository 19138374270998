.cctv p{
    font-size: 20px;
}

.antiterror{
    display: flex;
}

.aboutus-header{
    color: white;
}

.aboutus-header p{
    font-size: 1.1rem;
}

.spisok p {
    color: white;
    background: #24395b;
    padding: 10px 20px;
    border-radius: 10px;
    position: relative;
}



.services-navbar {
    z-index: 1;
    background: #192434;
    position: fixed;
    height: 100%; /* Высота экрана минус высота хедера */
    width: 250px;
    color: white;
    padding-top: 20px;
    padding-left: 30px;
    transform: translateX(-100%); /* Скрытый навбар */
    padding-top: 130px; /* Сдвигаем вниз на высоту хедера */
    left: 0;
    transition: transform 0.3s ease; /* Плавное появление */
}

.services-navbar.open {
    transform: translateX(0); /* Показываем навбар */
}

.services-navbar ul {
    list-style: none;
    padding-top: 30px; /* Уменьшили отступ из-за хедера */
}

.services-navbar li {
    margin-bottom: 10px;
    border-radius: 10px;
    transition: .3s ease all;
    cursor: pointer;
}

.services-navbar a {
    text-decoration: none;
    padding: 10px;
    color: white;
    display: block;
    border-radius: 5px 0 0 5px;
    transition: .3s ease all;
}

.services-navbar a:hover {
    background-color: #24395b;
}

.services-navbar .but-back {
    background-color: #00a5d3;
    color: white;
    border: none;
    font-size: 16px;
    font-weight: 600;
    padding: 10px;
    border-radius: 5px;
    cursor: pointer;
    width: 50%;
    margin-left: 10px;
}

.services-navbar button:hover {
    background-color: #0077a3;
}

/* Кнопка управления */
.toggle-button {
    position: absolute; /* Позиционируем кнопку */
    top: 120px; /* Сдвигаем вниз на высоту хедера + небольшой отступ */
    right: -25px; /* Половина кнопки за пределами навбара */
    width: 50px;
    height: 50px;
    background-color: #00a5d3;
    color: white;
    border: none;
    background: #12428d;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    z-index: 1100;
    font-size: 20px;

    transition:  0.3s ease all; /* Для плавного поворота кнопки */
}

.toggle-button:hover {
    background-color: #00aacb;
}

/* Когда навбар открыт, кнопка меняет положение */
.services-navbar.open .toggle-button {
    right: -25px; /* Полукруг остаётся на границе */
    transform: rotate(180deg); /* Поворот кнопки (например, '×' на '☰') */
}


.antiterror-content{
    padding-left:19%;
    padding-right: 13%;
    padding-bottom: 130px;
    padding-top: 130px;

}

.services-navbar ul li .active-link {
    color: white;
    background-color: #24395b;
}

.antiterror-content img{
    width: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    border-radius: 10px;
    margin-bottom: 10px;
}

.antiterror-content h2{
    margin-bottom: 20px;
}

.features ul li{
    font-size: 1.1rem;
    padding-bottom: 10px;
}

.features ul{
    margin-left: 20px;
    margin-bottom: 10px;
}

.forensic-button input{
    background: #00a5d3;
    color: white;
    border: none;
    padding: 10px;
    border-radius: 5px;
    cursor: pointer;
    font-weight: bold;
    font-size: 16px;
    width: 300px;

}
.antiterror-content p {
    /* Разрешает перенос слов, если они слишком длинные */
 /* Разбивает слова при необходимости */
    line-height: 1.6; /* Улучшает читаемость текста */
}

.bimage img{
    height: 400px;
    
}

.antiterror-content h3{
    margin-bottom: 20px;
}
.swal2-confirm.custom-alert-button {
    background-color: #0088cc !important;

}
.warn{
    margin-top: 100px;
}
.warning{
    text-align: center;
    margin-top: 20px;
    padding: 10px;
}

.swal2-confirm.custom-alert-button:hover {
    background-color: #006699; /* Темнее для эффекта при наведении */
}

@media (max-width: 1000px) {
    .antiterror-content {
        padding-left: 5%;
        padding-right: 5%;
        padding-bottom: 100px;
        padding-top: 100px;
        max-width: 100%; /* Контейнер адаптируется к ширине экрана */
    }

    .antiterror-content h1 {
        font-size: 24px; /* Уменьшение размера заголовка для мобильных */
        text-align: center; /* Центрирование заголовка */
    }

    .antiterror-content p {
        font-size: 16px; /* Уменьшение шрифта текста */
        /* Выравнивание текста по ширине */
        word-wrap: break-word;
        word-break: break-word;
    }
}
