.vacancies-content{
    height: 100vh;
    padding: 130px 13%;
}

.vacancies-content h1{
    text-align: center;
    word-spacing: 10px;
    letter-spacing: 4px;
    color: white;
    font-size: 28px;
    font-weight: 700;
    margin-bottom: 70px;
}

.vacancies{
    display: flex;
    justify-content: center;
}

.vacancies-list {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 100%;
}

.vacancies-block {
    display: flex;
    flex-direction: column;
    max-width: 800px;
    width: 100%; 
    
    background: #1b2839;
    padding: 20px;
    border-radius: 8px;
    position: relative;
    margin-bottom: 20px;
    flex-grow: 1; 
    justify-content: space-between;
    
    color: white;
}

.vacancies-description {
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;
    text-align: left;
}

.vac-time {
    font-size: 0.9em;
    color: #c9c9c9;
    margin-bottom: 10px;
}

.vac-title h1 {
    font-size: 1.5em;
    margin: 0;
    color: black;
    word-wrap: break-word;
    word-spacing: 0;
    letter-spacing: 0;
}

.vac-bottom {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap; 
}

.vac-icons {
    display: flex;
    gap: 15px;
    flex-wrap: wrap;
    color: #c9c9c9;
}

.vac-icons p {
    display: flex;
    align-items: center;  /* Вертикальное выравнивание по центру */
    font-size: 0.9em;
    margin: 0;  /* Убираем возможные внешние отступы */
    line-height: 1.5;  /* Корректное выравнивание текста */
}

.vac-icons p i {
    margin-right: 8px;  /* Отступ между иконкой и текстом */
    font-size: 1.2em;
    line-height: 1;  /* Устанавливаем для иконки line-height равным 1 */
}

.vac-button {
    margin-top: auto; /* Автоматическое смещение кнопки вниз */
    display: flex;
    justify-content: flex-end; /* Кнопка будет справа */
}



.vac-button input {
    border: none;
    font-weight: 600;
    color: white;
    font-size: 14px;
    background: linear-gradient(90deg, #009ec7, #00b7d0);
    padding: 7px 14px;
    border-radius: 5px;
    cursor: pointer;
    transition: 0.2s ease all;
}

.vac-button input:hover {
    transform: scale(1.05);
}

/* Медиазапросы для адаптивности */

@media (max-width: 768px) {
    .vacancies {
        padding: 80px 10%;
    }

    .vacancies-block {
        height: fit-content;
        padding: 15px;
    }

    .vac-title h1 {
        font-size: 1.3em;
    }

    .vac-icons {
        flex-direction: column;
        gap: 10px;
    }

}

@media (max-width: 480px) {
    .vacancies {
        padding: 50px 5%;
    }

    .vac-title h1 {
        font-size: 1.2em;
    }

    .vac-time {
        font-size: 0.8em;
    }

    .vac-icons p {
        font-size: 0.8em;
    }

}
