.header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100px;
    padding: 20px var(--left-padding);
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 100;
    background-color: #151c26d7;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
    color: white;
    
}

.navbaro li {
    position: relative; /* Make the services li a reference point for the dropdown */
}

.services-dropdown {
    position: absolute;
    top: 100%;
    left: 0;
    background-color: #151c26d7;
    padding: 10px;
    list-style: none;
    margin: 0;
    width:max-content;
    display: none; /* Hidden by default */

}

.navbaro li:hover .services-dropdown {
    display: block; /* Show on hover */
}

.services-dropdown li {
    margin: 20px 20px !important;
    font-weight: 500 !important;
    cursor: pointer;
}


.logo {
    height: 70px;
}

.navbaro {
    display: flex;
    justify-content:space-between;
    align-items: center;
}

.navbaro li { 
    position: relative;
    font-size: 16px;
    color: white;
    font-weight: 700;
    margin-left: 40px;
    transition: 0.3s;
    list-style: none;
    cursor: pointer;
}

.navbaro li::before {
    content: '';
    position: absolute;
    width: 0;
    height: 3px;
    background: #00a5d3;
    top: 100%;
    left: 0;
    transition: 0.3s;
}

.navbaro li:hover::before {
    width: 100%;
}

.navbaro li:hover {
    color: #00a5d3;
}

.navbaro li.active::before {
    width: 100%;
    background: linear-gradient(90deg, #009ec7, #00b7d0);
}

.navbaro li.active {
    
    color: #00a5d3;
}


.menu {
    display: none;
    font-size: 1.5rem;
    cursor: pointer;
}

@media (max-width: 1110px) {
    .carousel{
        display: none;
    }
    .menu {
        display: block;
    }
    .header {
        height: 80px;
    }

    .logo {
        height: 50px;
    }

    .navbaro {
        
        flex-direction: column;
        justify-content: space-evenly;
        position: fixed;
        top: 80px;
        left: 0;
        width: 100%;
        height: 0;
        background: #151c26b0;
        overflow: hidden;
        transition: height 0.4s ease;
        z-index: 9;
    }
    

    .navbaro.active {
        height: 60vh;
       
    }

    .navbaro li{
        margin-left: 0;
        display: none;
    }
    .navbaro.active li{
        display: block;
    }
}
