.vacancy-detail div{
    color: white;

}

.vacancy-detail .vac-bottom, .job-skills{
    margin-bottom: 70px;
}

.vacancy-detail .vac-bottom{
    margin-bottom: 20px;
}

.vacancy-detail .vac-button{
    justify-content: flex-start;
    margin-bottom: 20px;
}

.vacancy-detail .vac-bottom h2, .job-description h2, 
.job-key h2, .terms h2, .job-skills h2, .terms h2{
    margin-bottom: 10px;
}

.job-description{
    max-width: 40%;
}

.job-key{
    max-width: 80%;
}

.job-description{
    margin-bottom: 70px;
}

.job-key{
    margin-bottom: 60px;
}

.job-key ul, .job-skills ul{
    margin-left: 20px;
}

.job-key ul li, .job-skills ul li{
    padding-top: 10px;
}

.vac-two-blocks {
    display: flex;
    padding: 130px 13%;
    justify-content: flex-start;
}

.full-desc {
    margin-bottom: 20px;
}

.map-container {
    min-width: 600px;
    position: absolute;
    right: 13%;
    display: flex;
    flex-direction: column;
}

.map-container iframe {
    border-radius: 10px;
    width: 100%; /* Карта будет занимать всю ширину родительского контейнера */
    height: 100%; /* Высота подстраивается под ширину */
    aspect-ratio: 4 / 3; /* Сохраняет пропорции карты */
    border: none;
    margin-bottom: 20px;
}

.map-container img{
    margin-top: auto;
    width: 200px;
    margin-left: auto;
}

.map-container a{
    color: white;
    font-size: 23px;
    font-weight: 600;
    text-decoration: underline;
    transition: 0.3s ease all;
    margin-right: auto;
}

.map-container p{
    text-align: right;
}

.map-container a:hover{
    color: #00b5d0;
}



 
@media (max-width: 1368px) {
    .vac-two-blocks {
        flex-direction: column; 
        padding: 100px 5%;
    }
    .map-container{
        position: relative;
        right: 0;
        min-width: 100px;
    }

    .job-description{
        max-width: 100%;
    }

    .map-container img{
        margin-left: 0;
    }

    .map-container{
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    
    .map-container a{
        text-align: center;
    }
    
}