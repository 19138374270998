.partners-cont {
    position: relative;

}


.partners-content {
    background: white;
    position: relative;
    overflow: hidden;
    width: 100%;

}

.partner-carousel {

    display: flex;
    align-items: center;
}

.partner-logo {
    display: inline-block;
    margin: 0 30px;
}

.partner-logo img {
    width: 200px;
    height: 150px;
    object-fit: contain;
    transition: 0.3s ease all;
    border-radius: 10px;
}

.partner-logo img:hover {
    transform: scale(1.05);
    background: rgba(141, 141, 141, 0.177);
}

.carousel-button {
    border-radius: 10px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background-color: rgba(255, 255, 255, 0.8);
    border: none;
    cursor: pointer;
    padding: 10px;
}

.carousel-button.prev {
    left: 10px;
}

.carousel-button.next {
    right: 10px;
}






.become-partner-form {
    height: 400px;
    width: 100%;
    padding: 0 13%;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
}

.become-partner-form form {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
    padding: 20px;
    max-width: 500px;
    width: 100%; 
    box-sizing: border-box;
}

.become-partner-form form h1{
    word-spacing: 10px;
    letter-spacing: 4px;
    color: white;
    font-size: 28px;
    font-weight: 700;
}

.input-group {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.become-partner-form label {
    margin-bottom: 4px; /* Adjusted for better spacing */
    font-weight: bold;
    color: white;
    text-align: left;
}

.become-partner-form input {
    font-size: 16px;
    border-radius: 5px;
    border: none;
    padding: 10px 14px; /* Refined padding */
    width: 100%;
    box-sizing: border-box;
    margin-bottom: 10px; /* Adds spacing between fields */
}

.become-partner-form input[type="submit"] {
    font-weight: 600;
    color: white;
    font-size: 16px;
    background: linear-gradient(90deg, #009ec7, #00b7d0);
    padding: 12px 16px; /* Slightly larger padding for button */
    border-radius: 5px;
    cursor: pointer;
    transition: 0.3s ease all;
}

.become-partner-form input[type="submit"]:hover {
    transform: scale(1.02);
}

.name-phone {
    display: flex;
    gap: 12px; 
    width: 100%;
}

.name-phone .input-group {
    flex: 1;
}

.input-text{
    width: 100%;
}

.input-text textarea {
    height: 200px;
    width: 100%;
    font-size: 16px;
    border-radius: 5px;
    border: none;
    padding: 10px; /* Удобный отступ */
    box-sizing: border-box;
    resize: none; /* Запрет на изменение размеров */
    text-align: left; /* Выравнивание по левому краю */
    vertical-align: top; /* Выравнивание по верхнему краю */
    line-height: 1.5; /* Интерлиньяж для удобства чтения */
}


.become-partner-form {
    height: auto;

}

@media (max-width: 1000px) {
    .become-partner-form{
        padding: 0 5%;
    }
}