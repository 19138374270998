.service-carousel {
    display: flex;

    justify-content: center;
    position: relative;
    width: 100%;
    overflow: hidden; /* Hide overflow to prevent unwanted scrollbars */
    padding-bottom: 30px;
    
}

.section{
    height: 100vh; 
    padding: 0 13%;
    
}
#services-section {
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 100vh; /* Задайте нужную высоту */
}

#services-background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1; /* Фон позади всего содержимого */
}

.service-block {

    margin-top: 20px;
    flex: 0 0 20%;
    transition: transform 0.5s ease, opacity 0.5s ease;
    display: flex;
    flex-direction: column;
    opacity: 0.7;
    transform: scale(0.8);
    height: 420px;
    border-radius: 10px;
    background: white;
    cursor: pointer;

}

.service-block.center {
    cursor: default;
    margin-left: 10px;
    margin-right: 10px;
    border-radius: 10px;
    flex: 0 0 40%;
    opacity: 1;
    transform: scale(1.1);
    transition: 0.3s ease all;
}



.service-image {
    width: 100%;
    height: 40%;
    background-size: cover;
    background-position: center;
    border-radius: 10px 10px 0 0;
    margin-bottom: 10px;
}



.carousel-arrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    border: none;
    color: white;
    font-size: 24px;
    padding: 10px;
    cursor: pointer;
    border-radius: 50%;
    background: transparent;
    transition: background 0.3s;
}

.left-arrow {
    font-size: 32px;
    left: 0;
}

.right-arrow {
    font-size: 32px;
    right: 0;
}

.service-description {
    font-size: 0.9em;
    color: #7A7A7A;
    margin-top: 10px;
    padding: 0 20px;
}
.service-title {
   
    text-align: center;
    font-size: 24px;
    font-weight: 600;
    color: #333;   
    padding: 0 10px; 
}

.more-details-button {
    margin-top: auto;
    height: 50px;
    background: #00a7ca;
    color: #fff;
    font-weight: 600;
    font-size: 18px;
    border-radius: 0 0 10px 10px;
    border: none;
    cursor: pointer;
    outline: none;
   transition: 0.3s ease all;
}


.more-details-button:hover {
    background-color: #108298;
}

.section-title h1{
    word-spacing: 10px;
    letter-spacing: 4px;
    color: white;
    font-size: 28px;
    font-weight: 700;
    
}

.section-title{
    padding-top: 130px;
    text-align: center;
}

@media (max-width: 1372px) {
    .service-block {
        display: none;
    }
    .service-block.center {
        display: flex;
    }
    .service-title{
        font-size: 17px;
    }
    .service-image{
        min-width:250px;
    }
    .service-description{
        font-size: 0.8em;
    }
    .more-details-button{
        margin-top: auto;
        
    }
    
}

@media (max-width: 489px) {
    .service-carousel{
        position:static;
    }
}


