.forensic_logo img{
    max-width: 400px;
    height: 200px;
}

.forensic_logo_spec image{
    height: 150px;
}

.forensic_opis{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.forensic_block{
    margin-bottom: 70px;
    background-color: #151c26b0;
    border-radius: 10px;
    padding: 40px;
}

.forensic_back img{
    height: 300px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.bimage_vert{
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

.bimage_vert img{
    width: 300px !important;
    height: 400px;
}

.bimage_dlin{
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

.bimage_dlin img{
    width: 400px !important;
}



.forensic_back_avigilon img{
    width: 500px !important;
    height: 500px;
}


.forensic_back_brief img{
    width: 500px !important;
    height: 300px;
}

.forensic_back_wise img {
    max-width: 1200px !important;
    height: 350px;
}

.forensic_back_mile img{
    height: 350px;
    max-width: 500px !important;
}



.screensh img{
    max-width: 800px;
}

.lgscreen img{
    max-width: 600px;
}

.screenshoot img{
    max-width: 700px;
}

.datasheet{
    font-size: 18px;
    text-decoration: underline;
    font-weight: 600;
    color: #00c8ff;
    transition: .3s ease all;
}

.datasheet:hover{
    color: #192434;
}

.datasheet_content{
    display: flex;
    flex-direction: column;
}

.datasheet_content a{
    margin-bottom: 10px;
}

.become-partner-form .input-text-modal textarea{
    width: 100% !important;
}

.input-text-modal{
    width: 100%;
}

.modal-header{
    padding-left: 0;
    
}

.modal-header h2{
    font-weight: 600;
    
}

.osobennosti{
    background: #24395b;
    padding: 10px 20px;
    border-radius: 10px;
    margin-bottom: 10px;
}