@import '~@fortawesome/fontawesome-free/css/all.css';


.contacts-all{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 68vh;
    background: #1b2839;
    padding: 80px 13%;
    height: auto;
    z-index: 1000;
}

.contacts-content{
    display: flex;
}
.company{
    width: 50%;
}

.company div{
    margin: 20px 0;
}

.company div:first-child{
    margin: 0;
}

.company-logo{
    background-image: url('../../images/Logo.png');
    height: 70px;
    width: 97px;
    background-repeat: no-repeat;
    background-size: cover;
}

.interactive{
    color: #dcdcdc;
}

.links{
    display: flex;
    justify-content: left;
    gap: 30px;
}

.links i{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
    width: 50px;
    background-color: #00b5d1;
    border-radius: 50%;
    color: white;
    font-size: 20px;
    transition: 0.3s ease all;
}

.links i:hover{
    transform: scale(1.05);
    opacity: 0.7;
}

.contact-h2{
    width: 40%;
    padding-left: 15%;
}
.contacts-item{
    width: 100%;
}
.contacts-item h2{
    color: white;
    font-size: 24px;
    font-weight: 600;
}

.contacts-item h3{
    color: white;
    font-size: 16px;
    font-weight: 400;
}

.contacts-item:last-child{
    margin-bottom: 50px;
}

.contacts-item p{
    font-size: 16px;
    font-weight: 400;
    color: #dcdcdc;
}

.contacts-item div{
    margin: 10px 0;
}

.copyright{
    padding: 0;
    margin-top: auto;
    text-align: center;
    color: #dcdcdc;
}

@media (max-width: 768px) {

    .company{
        display: flex;
        flex-direction: column;
        width: 100%;
        align-items: center;
        justify-content: center;
    }
    .contacts-all {
        padding: 100px 5%;
    }

    .contacts-content {
        flex-direction: column;
        align-items: center;
        gap: 15px;
    }

    .links {
        gap: 30px;
    }

    .links i {
        height: 40px;
        width: 40px;
        font-size: 14px;
    }

    .interactive {
        font-size: 13px;
        text-align: center;
    }

    .contact-h2 {
        text-align: center; 
        padding: 0;
    }

    .contacts-item h2 {
        font-size: 18px;
    }

    .contacts-item h3,
    .contacts-item p {
        font-size: 13px;
    }
.contacts-item h3{
    margin-bottom: 0;
}
    .copyright {
        font-size: 11px;
    }
}