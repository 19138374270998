
.home-content {
    position: relative;
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: space-between;
    overflow: hidden; /* Предотвращает прокрутку */
    padding: 0 13%;
    z-index: 1;
    
}
.video-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 160, 200, 0.1);/* Затемнение с большей прозрачностью */
    z-index: -1; /* Расположено между видео и текстом */
}


.background-video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: -2;
}


.home {
    position: relative;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    text-align: center;
    color: white;
    
}

.home-text {

    display: flex;
    flex-direction: column;
    align-items: center;
}

.home-title {
    font-size: 2.5rem;
    font-weight: 700;
    word-wrap: break-word;
    opacity: 0;
    position: relative;
    left: -100%;
    transition: left 1.3s ease-out, opacity 1s ease-out;
}

.home-title.visible {
    left: 0;
    opacity: 1;
    transition-delay: 0.3s;
}

.home-description {
    width: 70%;
    margin-top: 20px;
    font-size: 1.3rem;
    font-weight: 600;
    opacity: 0;
    position: relative;
    left: -100%;
    transition: left 1.3s ease-out, opacity 1s ease-out;
}

.home-description.visible {
    left: 0;
    opacity: 1;
    transition-delay: 0.6s;
}

.contact-us {
    margin-top: 20px;
    opacity: 0;
    position: relative;
    left: -100%;
    transition: left 1.3s ease-out, opacity 1s ease-out;
}

.contact-us.visible {
    left: 0;
    opacity: 1;
    transition-delay: 0.9s;
}

.contact-us input {
    margin-top: 100px;
    background: var(--gradient-color);
    color: #fff;
    font-weight: 600;
    font-size: 20px;
    padding: 12px 30px;
    border-radius: 10px;
    border: none;
    cursor: pointer;
    outline: none;
    transition: background 0.3s ease 0.2s, transform 0.3s ease;
    height: 50px;
}

.contact-us input:hover {
    transform: scale(1.05);
}

@keyframes bounce {
    0%, 20%, 50%, 80%, 100% {
        transform: translateY(0);
    }
    40% {
        transform: translateY(-10px);
    }
    60% {
        transform: translateY(-5px);
    }
}

.contact-us-float {
    position: fixed;
    right: 20px;
    bottom: 120px;
    background-color: #00a5d3;
    color: white;
    border: none;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: background-color 0.3s, transform 0.3s;
    animation: bounce 2s infinite;
}

.contact-us-float:hover {
    background-color: #009ec7; 
}

.modal {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1001;
    
}
.disclaimer{
    text-align: justify
}
.modal-content {
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    width: 400px;
    position: relative;
}

.close-button {
    font-size: 30px;
    cursor: pointer;
    background: none;
    border: none;
    color: #333;
    font-weight: 600;
    padding: 0;
    line-height: 1;
}

.modal-content h2 {
    margin: 0;
    font-size: 24px;
    font-weight: 500;
}

.modal-content form {
    display: flex;
    flex-direction: column;
}

.modal-content form input,
.input-text-modal textarea {
    margin-bottom: 10px;
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 5px;
    outline: none;
}

.input-text-modal textarea {
    width: 100%;
    height: 200px;
    resize: none;
}

.modal-content form input[type="submit"] {
    background: var(--main-color);
    color: #fff;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.modal-content form input[type="submit"]:disabled {
    background-color: #b0b0b0;
    cursor: not-allowed;
}

.modal-content form input[type="submit"]:hover:not(:disabled) {
    background-color: var(--hover-color);
}

@media (max-width: 732px) {
    .home-description{
        font-size: 1rem;
        width: 100%;
    }
    .home-title{
        font-size: 1.5rem;
    }
    .contact-us input{
        height: 45px;
        padding: 10px 20px;
        font-size: 16px;
        margin-top: 20px;

    }
    
}
