.contact-button {
    position: fixed;
    bottom: 50px;
    right: 50px;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.4s ease, visibility 0.4s ease;
    background: linear-gradient(90deg, #009ec7, #00b7d0);
    color: white;
    font-size: 24px;
    border: none;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    cursor: pointer;
    box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.2);
    display: flex;
    justify-content: center;
    align-items: center;
    animation: bounce 2s infinite;
    z-index: 1000;
}

.contact-button.show {
    opacity: 1;
    visibility: visible;
}

.contact-button:hover {
    transform: scale(1.1);
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.3);
}

@keyframes bounce {
    0%, 100% {
        transform: translateY(0);
    }
    50% {
        transform: translateY(-5px);
    }
}

@media (max-width: 768px) {
    .contact-button {
        bottom: 30px;
        right: 30px;
    }
}
