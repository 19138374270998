@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap');

:root {
  --left-padding: 13%;
  --main-color: #00a5d3;
  --hover-color: #005a72;
  --gradient-color: linear-gradient(90deg, #009ec7, #00b7d0);
  --black-color: black;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Open Sans", sans-serif;
}

body {
  overflow-x: hidden;
  background: linear-gradient(90deg, #1f304f, #4b76aa);
  /* background: #03656d; */

}

a {
  text-decoration: none;
}



.container {
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

h2 {
  font-size: 24px;
  font-weight: 700;
}

h3 {
  font-size: 20px;
  font-weight: 700;
}


